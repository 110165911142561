body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.image-container1 {
  position: relative; /* Needed to position the overlay */
  width: 100%; /* Set desired width or make it responsive */
   /* Set desired height */
}

.background-image1 {
  width: 100%;
  height: 100%;
  object-fit: cover; /* This will cover the container, you can use 'contain' to fit inside */
}

.overlay1 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Dark blue with opacity */
  /* Allows clicks to pass through to the image below */
}

.image-container2 {
  position: relative; /* Needed to position the overlay */
  width: 100%; /* Set desired width or make it responsive */
   /* Set desired height */
}

.background-image2 {
  width: 100%;
  height: 100%;
  object-fit: cover; /* This will cover the container, you can use 'contain' to fit inside */
}

.overlay2 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Dark blue with opacity */
  /* Allows clicks to pass through to the image below */
}

.image-container3 {
  position: relative; /* Needed to position the overlay */
  width: 600px; /* Set desired width or make it responsive */
   /* Set desired height */
}

.background-image3 {
  width: 100%;
  height: 100%;
  object-fit: cover; /* This will cover the container, you can use 'contain' to fit inside */
}

.overlay3 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-color: rgba(0, 0, 0, 0.5); Dark blue with opacity */
  /* Allows clicks to pass through to the image below */
}

.image-container4 {
  position: relative; /* Needed to position the overlay */
  width: 600px; /* Set desired width or make it responsive */
  /* Set desired height */
}

.background-image4 {
  width: 100%;
  height: 100%;
  object-fit: cover; /* This will cover the container, you can use 'contain' to fit inside */
}

.overlay4 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  /* Allows clicks to pass through to the image below */
}
